@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.img-subtext {
  padding-top: 100px;
  color: white;
  font-size: 40px;
  font-family: 'Montserrat';
  font-style: italic;
}

.container-particle {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  height: 80vh;
  width: 100vw;
  text-align: center;
}

.nav-color {
  background-color: #212529;
}

.navbar-item {
  font-family: 'Roboto';
  color: white!important;
}

.dropdown-item {
  font-family: 'Roboto';
  color: black!important;
}

.navbar-brand {
  font-family: 'Montserrat';
  color: white!important;
  font-weight: bold;
}

.particle-text {
  margin-top: -150px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
  color: white;
  font-size: 60px;
  font-family: 'Montserrat';
  font-weight: bolder;
}

.particle-subtext {
  padding-top: 150px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
  color: white;
  font-size: 50px;
  font-family: 'Montserrat';
  font-style: italic;
}

.home {
  text-align: center;
}

.home-text {
  font-family: 'Roboto';
  padding-top: 30px;
}

.icon-text-title {
  font-family: 'Roboto';
  padding-top: 30px;
  font-weight: bold;
}

.icon-text {
  font-family: 'Roboto';
  padding-top: 30px;
}

.footer {
  background-color: #39A2AE;
  margin-top: 100px;
  padding-bottom: 20px;
}

.footer-text {
  font-family: 'Roboto';
  padding-top: 30px;
}

.tutoring-icon {
  padding-top: 50px;
}

.tutoring {
  font-family: 'Roboto';
}

.tutoring-title {
  font-weight: bold;
  padding-top: 30px;
}

.tutoring-subtitle {
  font-style: italic;
  padding-top: 20px;
  color: grey;
}

.review-subtitle {
  font-style: italic;
  color: grey;
}

.grey-div {
  background-color: lightgray;
  padding-top: 25px;
  padding-bottom: 50px;
}

.white-div {
  padding-bottom: 50px;
}

.carousel-container {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.timeline {
  padding-top: 30px;
}

img {  
  margin-top: 10px;
  max-width: 20%;  
  height: auto;  
  object-fit: cover;
  }  

.about-me {
  text-align: left;
  padding-top: 30px;
}

.coming-soon {
  margin-left: 45px;
}

.course-container {
  margin-top: 30px;
}

.test-container {
  text-align: left;
}

.test-button {
  float: right;
  margin-top: -40px;
}

.result-button {
  margin: auto;
}

.quiz-container {
  margin: auto;
  max-width: 700px;
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 25px;
  padding: 30px 60px;
}

.quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #39A2AE;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container ul .selected-answer {
  background: #39A2AE;
  border: 1px solid #39A2AE;
}

.quiz-container button {
  background: #39A2AE;
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #39A2AE;
  font-size: 22px;
}

.test-img {
  max-width: 100%;
  height: auto;
}